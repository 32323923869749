import styled, { keyframes } from "styled-components"
import themeSettings from "../../styles/theme"

const eyeSize = "14vw"

const blink = keyframes`
  0% { height: ${eyeSize} };
  80% { height: ${eyeSize} };
  81.5% { height: 4vw };
  83% { height: ${eyeSize} };
  100% { height: ${eyeSize} }
`

export const Section = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 100%;
  height: 100vh;

  font-size: ${themeSettings.fontSizes.large};
  display: inline-block;

  @media screen and (max-width: 1280px) {
    font-size: calc(${themeSettings.fontSizes.medium} * 1.8);
  }

  @media screen and (max-width: 768px) {
    font-size: calc(${themeSettings.fontSizes.medium} * 1.6);
  }

  @media screen and (max-width: 414px) {
    font-size: calc(${themeSettings.fontSizes.medium} * 1);
  }

  @media screen and (max-width: 320px) {
    font-size: calc(${themeSettings.fontSizes.medium} * 0.8);
  }
`

export const TextContainer = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  line-height: 0.8;

  & > h3 {
    position: absolute;
    &:nth-child(1) {
      top: calc(${themeSettings.navHeight}px + ${themeSettings.margins.page});
      left: calc(${themeSettings.margins.page} * 0.8);
      @media screen and (max-width: 414px) {
        top: calc(${themeSettings.mobileNavHeight}px + ${themeSettings.mobileMargins.page});
        left: calc(${themeSettings.mobileMargins.page} * 0.8);
      }
    }
    &:nth-child(2) {
      top: calc(${themeSettings.navHeight}px + ${themeSettings.margins.page});
      right: calc(${themeSettings.margins.page} * 0.6);
      @media screen and (max-width: 414px) {
        top: calc(${themeSettings.mobileNavHeight}px + ${themeSettings.mobileMargins.page});
        right: calc(${themeSettings.mobileMargins.page} * 0.6);
      }
    }
    &:nth-child(3) {
      bottom: calc(${themeSettings.margins.page} * 1.4);
      left: ${themeSettings.margins.page};
      @media screen and (max-width: 414px) {
        bottom: calc(${themeSettings.mobileMargins.page} * 1.4);
        left: ${themeSettings.mobileMargins.page};
      }
    }
  }

  & > span {
    position: absolute;
    bottom: calc(${themeSettings.margins.page} * 1.4);
    right: ${themeSettings.margins.page};
    @media screen and (max-width: 414px) {
      bottom: calc(${themeSettings.mobileMargins.page} * 1.4);
      right: ${themeSettings.mobileMargins.page};
    }
  }
`

export const EyeContainer = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  text-align: center;

  padding-bottom: 10vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 414px) {
    padding-bottom: 2vh;
  }

  & > h3 {
    width: 70%;
    margin: 0 auto;
  }
`

export const Eyes = styled.div`
  position: relative;
  width: 100%;
  height: ${eyeSize};
  margin-bottom: ${themeSettings.margins.large};

  display: flex;
  align-items: center;
  justify-content: center;
`

export const Eye = styled.div`
  position: relative;
  width: ${eyeSize};
  height: ${eyeSize};
  background-color: ${themeSettings.colors.dark};
  border-radius: 50%;
  margin: 0 20px;
  transition: height 0.2s ease;
  animation: ${blink} 3s infinite;
`

export const PupilContainer = styled.div`
  position: absolute;
  width: 50%;
  height: 50%;
  border-radius: 50%;
  top: calc((${eyeSize} / 2) - 25%);
  left: calc((${eyeSize} / 2) - 25%);
`

export const Pupil = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60%;
  height: 60%;
  background-color: ${themeSettings.colors.light};
  border-radius: 50%;
  transform: translate(-50%, -50%);
`